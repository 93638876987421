<template>
  <div class="pt-3">
    <!-- <div class="dashboard__container--header">
      <h2>New Applicants</h2>
    </div> -->
    <Loader v-if="!groupApplications || groupApplications.length == 0" />
    <div class="dashboard__container--body pt-3" style="width:100%;">
      <UserTable :groupUsers="groupApplications" :group="group" action="applicants" />
      
    </div>
</div>
</template>

<style scoped>
  .fa-solid {
    opacity: 0.7;
  }
  .fa-solid:hover {
    opacity: 1;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import UserTable from '@/components/UserTable.vue'
// import algoliasearch from 'algoliasearch/lite';
import router from '@/router'
import * as moment from 'moment'
import StarRating from 'vue-star-rating'
const fb = require('../../firebaseConfig.js')

export default {
  props: ['group'],
  name: 'groupApplicants',
  data: () => ({
    message: '',
    showAll: true,
    performingRequest: false,
    performingRequest2: false,
    columns: [
      {
        field: 'photoUrl',
        sortable: false,
        width:'42px',
        tdClass: 'text-center',
      },
      {
        label: '',
        field: 'fullName',
        sortable: false,
        width:'180px',
      },
      {
        label: 'Onboarded',
        field: 'onboarded',
        sortable: false,
        width:'120px',
      },
      {
        label: 'City',
        field: 'address.city',
        sortable: false,
        width:'120px',
      },
      {
        label: 'State',
        field: 'address.state',
        sortable: false,
        width:'60px',
      },
      {
        label: 'Created',
        field: 'created',
        sortable: false,
        width:'120px',
      },
      {
        label: 'Phone',
        field: 'phone',
        sortable: false,
        width:'120px',
      },
      {
        label: 'Background',
        field: 'check',
        sortable: false,
      },
      {
        label: 'Drug',
        field: 'drug',
        sortable: false,
      },
      {
        label: 'Skills',
        field: 'skills',
        sortable: false,
      },
      {
        label: 'Actions',
        field: 'actions',
        sortable: false,
        width:'140px',
      }
    ]
  }),
  computed: {
    ...mapState(['currentUser', 'userProfile', 'groupApplications']),
  },
  components: {
    Loader,
    StarRating,
    UserTable
  },
  created () {
    if (!this.groupApplications || this.groupApplications.length < 1) {
      this.$store.dispatch("getGroupApplications", this.$route.params.id);
    }
  },
  methods: {
    // addUser(item) {
    //   console.log(item)
    //   let group = this.group
    //   this.performingRequest = true;
    //   this.$store.dispatch("addUserToGroup", {
    //     id: item.objectID,
    //     group: this.group
    //   })
    //   setTimeout(() => {
    //     this.performingRequest = false;
    //     document
    //     .querySelectorAll('.ais-SearchBox-input')
    //     .forEach((e) => (e.value = ''))
    //     document.querySelectorAll('.ais-Hits-item').forEach((e) => e.remove())
    //   }, 250)
    // },
    onTextPayroll(user) {
      // user.updatePayrollReminderSent = true
      let newText = {
        name: user.firstName,
        phone: user.phone,
        userId: user.id,
        type: 'updatePayroll'
      }
      this.$store.dispatch('updatePayrollText', newText)
      
      // fb.usersCollection.doc(user.id).update({
      //   updatePayrollReminderSent: fb.firestore.FieldValue.serverTimestamp()
      // })
    },
    isWaitlisted(user) {
      if (!this.groupWaitlist || this.groupWaitlist.length == 0) {
        return false
      } else if (this.groupWaitlist.includes(user)) {
        return true
      } else {
        return false
      }
    },
    acceptUser(item, index) {
      console.log(item)
      console.log(index)
      this.$store.dispatch("acceptUserToGroup", {
        user: item,
        group: this.group
      })
      let groupApplications = this.groupApplications
      groupApplications.splice(index, 1)
    },
    holdUser(item, index) {
      this.$store.dispatch("waitlistUserToGroup", {
        user: item,
        group: this.group
      })
      let groupApplications = this.groupApplications
      groupApplications.splice(index, 1)
    },
    deleteUser(item, index) {
      this.$store.dispatch("removeUserApplicationFromGroup", {
        group: this.group,
        user: item
      })
      let groupApplications = this.groupApplications
      groupApplications.splice(index, 1)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY')
      } else {
        return null
      }
    },
    // sendMessage () {
    //   this.performingRequest2 = true
    //   let newMessage = {
    //     groupId: this.group.id,
    //     groupUsers: this.groupUsers,
    //     message: this.message,
    //     from: this.userProfile.twilioNumber || null
    //   }
    //   this.$store.dispatch('updateGroupStaff', newMessage)
    //   setTimeout(() => {
    //       this.performingRequest2 = false,
    //       this.message = ''
    //   }, 1000)
    // },
    // updateStaff() {
    //   let group = this.group
    //   this.$store.dispatch('updateGroupStaff', group)
    // },
    updateGroup() {
      let group = this.group
      this.$store.dispatch('updateGroup', group)
    },
    onRowClick(params) {
      let url = `/users/` + params.row.id
      router.push(url)
    },
    // goBack() {
    //   router.go(-1)
    // },
  },
  destroyed () {
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest2 = null
    delete this.performingRequest2
    this.message = null
    delete this.message
    this.showAll = null
    delete this.showAll
    this.columns = null
    delete this.columns
    this.$store.dispatch("clearGroupApplications");
  }
}
</script>