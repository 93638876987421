import { render, staticRenderFns } from "./Applicants.vue?vue&type=template&id=65c8f917&scoped=true"
import script from "./Applicants.vue?vue&type=script&lang=js"
export * from "./Applicants.vue?vue&type=script&lang=js"
import style0 from "./Applicants.vue?vue&type=style&index=0&id=65c8f917&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65c8f917",
  null
  
)

export default component.exports